<template>
  <div
    class="banner-icon-description-cta"
    :class="'banner-icon-description-cta-' + type"
  >
    <div
      class="icon icon-mobile"
      :class="'icon-' + type"
    >
      <uikit-house-building-icon class="house-building-icon" />
    </div>
    <div class="content">
      <div
        class="icon icon-desktop"
        :class="'icon-' + type"
      >
        <uikit-house-building-icon class="house-building-icon" />
      </div>
      <div class="text">
        <h3
          v-if="title"
          class="title"
          v-html="title"
        />
        <div class="description">
          <span>{{ description }}</span>
        </div>
      </div>
      <div class="cta-mobile">
        <uikit-button
          :type="type"
          v-bind="mobileButtonProps"
          @click="handleClick"
          textWrap
        >
          {{ ctaText }}
        </uikit-button>
      </div>
    </div>
    <div class="cta-desktop">
      <div>
        <uikit-button
          :type="type"
          v-bind="desktopButtonProps"
          @click="handleClick"
        >
          {{ ctaText }}
        </uikit-button>
      </div>
    </div>
  </div>
</template>

<script>
import { UikitButton, UikitHouseBuildingIcon, useScreenSizeStore } from '@hz/ui-kit'
import { mapState } from 'pinia'

export default {
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    ctaText: {
      type: String,
      default: null,
    },
    btnProps: {
      type: Object,
      default: () => {},
    },
    mobileBtnProps: {
      type: Object,
      default: () => {},
    },
    desktopBtnProps: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['handleClick'],
  components: {
    UikitButton,
    UikitHouseBuildingIcon,
  },
  computed: {
    ...mapState(useScreenSizeStore, ['isMobileWidth']),
    mobileButtonProps() {
      return { ...this.btnProps, ...this.mobileBtnProps }
    },
    desktopButtonProps() {
      return { ...this.btnProps, ...this.desktopBtnProps }
    },
  },
  methods: {
    handleClick() {
      this.$emit('handleClick')
    },
  },
}
</script>

<style lang="less" scoped>
.banner-icon-description-cta {
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 12px;

  &-primary {
    background: var(--ds-color-primary-25);

    .content :deep(strong) {
      color: var(--ds-color-primary-100);
    }
  }
  &-secondary {
    background: var(--ds-color-primary-25);

    .content :deep(strong) {
      color: var(--ds-color-primary-100);
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 48px;
    min-height: 48px;
    border-radius: 50%;
    background: var(--ds-color-white-100);

    &-primary {
      color: var(--ds-color-primary-100);
    }
    &-secondary {
      color: var(--ds-color-primary-100);
    }
  }

  .icon-mobile {
    display: inherit;
  }
  .icon-desktop {
    display: none;
  }

  .content {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h3 {
      color: var(--ds-color-gray-100);
      margin: 0;
      margin-bottom: 8px;
      font-size: var(--ds-font-md);
      font-weight: var(--ds-weight-semi-bold);
    }

    .description {
      color: var(--ds-color-gray-50);
      font-size: var(--ds-font-sm);
      font-weight: 300;
    }
  }

  .cta-mobile {
    display: inherit;
    margin-top: 20px;
  }
  .cta-desktop {
    display: none;
  }
}
@media @bp-desktop {
  .banner-icon-description-cta {
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .content {
      display: flex;
      flex-direction: row;
      align-items: center;

      .text {
        margin-left: 24px;
        max-width: 1230px;
      }

      h3 {
        font-size: var(--ds-font-lg);
      }

      .description {
        font-size: var(--ds-font-md);
      }
    }

    .icon-mobile {
      display: none;
    }
    .icon-desktop {
      display: inherit;
    }

    .cta-desktop {
      display: inherit;
      margin-left: 24px;
    }
    .cta-mobile {
      display: none;
    }
  }
}
</style>
